@import "../../variables/variables.scss";

.row-one {
  background-color: $bg_body_color;

  .left {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
    padding-left: calc(var(--bs-gutter-x) * 0) !important;
    background-color: $darkGray;
    // height: 85vh;
    // overflow-y: auto !important;

    .categories {
      background-color: $darkGray;
      margin-top: 10px;

      .categories-inner {
        // font-size: ;
        .card-header {
          background-color: $darkGray;
          border: none !important;
          height: auto !important;

          button {
            background-color: $darkGray !important;
            width: 100%;
            border: none !important;
            display: flex !important;
            align-items: center;
            justify-content: space-between;
          }
        }

        .card-body {
          background-color: #ececec;
          padding: 0px;
          padding-left: 20px;
          overflow-y: auto;
          height: 200px;
        }

        .products {
          border: 0 !important;
        }

        .categories-content {
          font-weight: 600;
          color: black;
        }

        .collapse {
          color: black;
          .categories-links {
            list-style-type: none;
            color: black;
            font-size: 15px;
            cursor: pointer;

            img {
              height: 20px;
              transition: 0.5s;
            }

            a {
              color: black;
              text-decoration: none;
            }

            &:hover {
              a {
                color: $primary;
              }
              img {
                margin-left: 10px !important;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }

  .right {
    // height: 85vh;
    padding-right: calc(var(--bs-gutter-x) * 0);
    overflow: hidden !important;

    .banner {
      width: 100% !important;
      border-radius: 10px 10px 0px 0px;
      .banner-image{
        border-radius: 10px 10px 0px 0px;
        
      }

      @media (min-width: 992px) {
        height: 100%;
      }

      margin: 10px 0px;
      position: relative;

      .banner-image {
        width: 100% !important;
        height: 8vh;
        // object-fit: fill;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .right {
      padding-left: calc(var(--bs-gutter-x) * 0);
      // background-color: red;

      .banner-image {
        object-fit: cover !important;
        height: 50vh !important;
      }
    }

    // .left {
    //   height: 200px !important;
    // }
  }
}

.banner {
  width: 100% !important;
  // height: 500px !important;

  img {
    width: 100% !important;
    height: 100% !important;
    // object-fit: none !important ;
    background-position: center;
    background-size: cover;
  }
}

.banner-products-slide {
  @include container;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  // border-radius: 10px;
  background-color: white;
  // margin-top: 14px;
  position: relative;
  
  z-index: 999;

  .products-slide {
    // border-right: 0.5px solid gray;
    text-align: center;
    // margin: 20px;

    img {
      width: 100%;
      height: 45px;
      object-fit: contain;
    }

    p {
      // padding-top: 20px;
      font: {
        size: 10px;
        // weight: 600;
      }
    }
  }

  .slick-next {
    display: block !important;
    right: 0 !important;
    z-index: 999 !important;
  }

  .slick-next:before {
    color: black !important;
  }

  .slick-prev {
    display: block !important;
    left: 0 !important;
    z-index: 999 !important;
  }

  .slick-prev:before {
    color: black !important;
  }
}

.banner-sidebar-category{
  font-weight: 600;
  letter-spacing: 0.5px;
}


.parent-accrodion-header{
  border-radius: 10px 10px 0px 0px;
  height: 46px;
  padding: 15px;
  background-color: #0443C0;
}

.parent-accrodion-header>button{
  padding: 0px !important;
  border: none !important;
  // background-color: transparent !important;
  background-color: #0443C0 !important;
}
.parent-accrodion-header>button>button{
  width: 100%;
  padding: 0px !important;
  border: none !important;
  // background-color: transparent !important;
  background-color: #0443C0 !important;
  color: #ececec;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-weight: 600;
  letter-spacing: 1px;

}
.parent-accrodion-item>.accordion-collapse>.accordion-body{
  padding: 0px !important;
}
.parent-accrodion-item>.accordion-collapse>.accordion-body>.accordion>.products:last-child>.card-header{
  border-radius: 0px 0px 10px 10px !important;
  background-color: yellow;
}

.accordion-collapse{
  border-radius: 0px 0px 10px 10px;
}


@media (min-width:1024px) {
  .products-slide{

    border-right: 2px solid rgb(150, 150, 149);
  }

}



.categories-links {
  position: relative;
  padding: 12px;
}

.categories-links::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  
  background: linear-gradient(90deg, #0443C0, transparent);
  transition: left 0.5s ease-in-out; /* Add smooth transition */
  z-index: -1; /* Ensure it is behind the text */
}

.categories-links:hover::before {
  left: 0;
}

.categories-links:hover {
  color: white !important;
  position: relative; /* Make sure the hover effect stays within bounds */
  z-index: 1; /* Ensure the text is above the gradient */
}

.categories-links:hover .categories-links-subcategories {
  color: #fff !important;
  padding-left: 20px !important;
}

.categories-links .categories-links-subcategories {
  padding-left: 20px !important;
}

.card-body {
  padding: 0px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
 max-height: none !important; 
}
@media  (max-width:"768px") {
  .row-one{
    display: none !important;
  }
}
.row-ones {
  background-color: $bg_body_color;

  .left {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
    padding-left: calc(var(--bs-gutter-x) * 0) !important;
    background-color: $darkGray;
    // height: 85vh;
    // overflow-y: auto !important;

    .categories {
      background-color: $darkGray;
      margin-top: 10px;

      .categories-inner {
        // font-size: ;
        .card-header {
          background-color: $darkGray;
          border: none !important;
          height: auto !important;

          button {
            background-color: $darkGray !important;
            width: 100%;
            border: none !important;
            display: flex !important;
            align-items: center;
            justify-content: space-between;
          }
        }

        .card-body {
          background-color: #ececec;
          padding: 0px;
          padding-left: 20px;
          overflow: hidden;
        }

        .products {
          border: 0 !important;
        }

        .categories-content {
          font-weight: 600;
          color: black;
        }

        .collapse {
          color: black;
          .categories-links {
            list-style-type: none;
            color: black;
            font-size: 15px;
            cursor: pointer;

            img {
              height: 20px;
              transition: 0.5s;
            }

            a {
              color: black;
              text-decoration: none;
            }

            &:hover {
              a {
                color: $primary;
              }
              img {
                margin-left: 10px !important;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }

  .right {
    // height: 85vh;
    padding-right: calc(var(--bs-gutter-x) * 0);
    overflow: hidden !important;

    .banner {
      width: 100% !important;
      border-radius: 10px 10px 0px 0px;
      .banner-image{
        border-radius: 10px 10px 0px 0px;
        
      }

      @media (min-width: 992px) {
        height: 100%;
      }

      margin: 10px 0px;
      position: relative;

      .banner-image {
        width: 100% !important;
        height: 8vh;
        // object-fit: fill;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .right {
      padding-left: calc(var(--bs-gutter-x) * 0);
      // background-color: red;

      .banner-image {
        object-fit: cover !important;
        height: 50vh !important;
      }
    }

    // .left {
    //   height: 200px !important;
    // }
  }
}
.drawer-heading{
  font-size: .6875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: .9375rem;
  margin-bottom: .625rem;
  opacity: .3;
}
.filtercater button{
  border: 0px;
  background-color: transparent;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.filtercater button svg{

}

.breadcrumb-item+.breadcrumb-item::before {

  content: var(--bs-breadcrumb-divider, ">") !important; /* rtl: var(--bs-breadcrumb-divider, "/") */;
}