$backgroundcolor:#EEEDEB;
$heading-background-color:#E6B9A6;
$subheading-backgound-color:#939185;
$content-color:#2F3645;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.about-section {
  color: rgb(22, 19, 19);
  // padding: 20px;
  // background-color: #090909;
  background-color: $backgroundcolor !important;
  color: $content-color !important;
}
.css-r40f8v-MuiTypography-root{
  font-weight: 700 !important;
  color: none !important;
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.about-heading {
  margin-top: 10px;
  text-align: center;
  font-family: "arial", sans-serif;
  font-weight: 600;
  // color: #0443c0;
  color: #2f3645;
  // background: linear-gradient(
  //   210deg,
  //   rgba(206, 211, 212, 1) 0%,
  //   rgba(245, 246, 246, 1) 56%,
  //   rgba(168, 176, 178, 1) 100%
  // );
  padding: 10px;
  border-radius: 8px;
}

// .about-history,
// .about-our-product,
// .about-ecommerce-platform,
// .Commitment-to-Quality-container,
// .Vision-and-Values {
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
  //   0px 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0px -3px 0px rgba(0, 0, 0, 0.2);
  // background-color: #ffffff;
  // padding: 5px;
  // border-radius: 5px;
// }

.about-history {
  display: flex;
  // align-items: center;
  // margin: 20px 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;

  aside {
    flex: 1;
    margin-right: 20px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      // border: 2px solid red;
      border-radius: 5px;
    }
  }

  article {
    flex: 2;
    line-height: 1.5;
    word-spacing: 5px;
    // text-shadow: 0px 0px 1px $content-color;
  }
}

.about-our-product {
  // margin: 40px 0;

  .about-our-product-heading {
    // text-decoration: underline;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-top: 20px;
 
  }

  .about-our-product-details {
    line-height: 2;
    word-spacing: 4%;
    // text-shadow: 0px 0px 1px #0443c0;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
  } 

  .about-our-product-content {
    position: relative; 
  }


  .about-our-product-content.in-view li {
    opacity: 1;
    transform: translateX(0);
  }
}
.css-h93ljk-MuiTypography-root{
  font-family: outfit !important;
  font-size: 21px !important;
  p {
font-family: Arial, Helvetica, sans-serif !important;
}
 
}

.about-ecommerce-platform {
  margin: 20px 0;

  .about-ecommerce-platform-heading {
    // text-decoration: underline;
    font-family: outfit;
    // font-size: 17px;
    // text-align: center;
    // margin-bottom: 10px;
    // padding-top: 10px;
  }

  .about-ecommerce-platform-content {
    line-height: 1.5;
    word-spacing: 4%;
    // text-shadow: 0px 0px 1px #0443c0;
  }
}

.about-ecommerce-platform-content > .scroller-ul > ul {
  // list-style: disc !important; 
  padding-left: 20px; 
}

.about-ecommerce-platform-content > .scroller-ul > ul li {
  position: relative; 
  padding-left: 30px; 
}

// .about-ecommerce-platform-content > .scroller-ul > ul li::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 30px;
//   height: 30px; 
//   background-image: url("../Assets/About_page_Sproket_icon.png");
//   background-size: cover; 
//   background-repeat: no-repeat;
//   animation: rotate 2s linear infinite;
// }

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.Commitment-to-Quality-container {
  margin: 20px 0;
  // .Commitment-to-Quality-heading {
    // text-align: center;
    // margin-bottom: 10px;
    // text-decoration: underline;
    // padding-top: 20px;
    // text-decoration: underline;

  // }
  .Commitment-to-Quality-content {
    // font-size: 14px;
    // line-height: 2;
    // word-spacing: 4%;
    // text-shadow: 0px 0px 1px #0443c0;
    margin-bottom: 20px;
  }
}

.Vision-and-Values-heading {
  text-align: center;
  margin-bottom: 10px;
  // text-decoration: underline;
  padding-top: 20px;
}


.vision-and-values>b>div, .vision-and-values>.scroller-ul>ul>li{
  line-height: 1.5;
  word-spacing: 4%;
  // text-shadow: 0px 0px 1px #0443c0;
}

.about-card{
  // display: flex;
  // align-items: start;
  // justify-content: space-evenly;
  background-color: #EEEDEB;
}

 .about-crd .MuiCardContent-root{
  height: 250px;
 }


 .about-page-ul-li{
  list-style-type: disc !important;
  
 }
