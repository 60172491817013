/* General Styles */
.blog-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .blog-header h5 {
    font-size: 1.75rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Section Title */
  .section-title {
    font-weight: 700;
    font-size: 1.3rem;
    color: #34495e;
  }
  
  /* Blog Section Styling */
  .blog-section {
    margin-top: 30px;
  }
  
  .blog-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
  }
  
  .blog-img {
    max-width: 48%;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-img-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .blog-footer {
    margin-top: 40px;
    font-weight: 600;
    color: #333;
  }
  
  .fastener-list {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .fastener-list li {
    margin-bottom: 15px;
    font-size: 1.1rem;
    color: #555;
    line-height: 1.8;
  }
  
  .highlight {
    font-weight: 700;
    color: #2c3e50;
  }
  
  .blog-img:hover {
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
  }
  
  /* img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  img:hover {
    transform: scale(1.03);
    transition: 0.3s ease;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 768px) {
    .blog-container {
      width: 90%;
    }
  
    .blog-flex {
      flex-direction: column;
      align-items: center;
    }
  
    .blog-img {
      width: 90%;
      margin-bottom: 20px;
    }
  }
  