.progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transition: all 100ms linear;
  }
  
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .progress-wrap.scroll-blue {
    /* background: blue; */
  }
  
  .progress-wrap svg {
    position: absolute;
    width: 70%;
    height: 70%;
  }
  
  .progress-circle {
    fill: none;
    stroke-width: 4;
    stroke: #2622fa; 
    transition: stroke-dashoffset 10ms linear;
  }
  
  .scroll-icon {
    position: relative;
    font-size: 18px;
    color: #2622fa;
    z-index: 1;
  }
  