.label-s{
    font-size: 12px;
    margin: 13px;
}



.sdsds{
    background-color:#0443C0;
    color: white;
    font-size: 15px; /* Change font size */
 /* Change text color */
     /* Make text bold */
    letter-spacing: 1px; /* Add spacing between letters */
    line-height: 1.5; /* Adjust line height */
    text-align:start;
    padding :10px;
    overflow-x:var(20px);
    width: 35%;
}
/* .form-control{
    width:30%;
} */
.btnv{
    background-color: rgb(255, 94, 0);
    color: white;
    font-weight: 500;
}

    .MuiBreadcrumbs-ol
    {
        justify-content: center;
    }
    .input-group{
        width: 85%;
    }
    @media (max-width: 768px) {
        .input-group {
            width: 100% !important;
           
        }
        .input-group .sdsds{
            font-size: 10px;
        }
    }