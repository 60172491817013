.container {
    padding: 20px;
  }
  
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  
  .list-of-step {
    margin-top: 20px;
    border: 1px solid #e0e0e0; /* Add border for separation */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Add padding inside step list */
    background-color: #f9f9f9; /* Light background color */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  
  .step-wrapper {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 20px; /* Space between items */
    margin-bottom: 20px; /* Space between rows of steps */
  }
  
  .step-item {
    flex: 1 1 calc(50% - 20px); /* Flex-basis for 2 items per row with gap */
    display: flex;
    align-items: flex-start; /* Align items at the start */
    padding: 15px; /* Padding around each step */
    border: 1px solid #e0e0e0; /* Border around each step */
    border-radius: 8px; /* Rounded corners for individual steps */
    position: relative; /* Position relative for badge alignment */
    background-color: #f9f9f9; /* Background color */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; /* Optional shadow for steps */
  }
  
  .step-item h5 {
    font-size: 1.125rem; /* Slightly larger heading */
    font-weight: 600; /* Semi-bold heading */
    margin-bottom: 10px;
  }
  
  .step-item p {
    font-size: 15px; /* Slightly smaller text */
    color: #555; /* Darker text color for readability */
    line-height: 1.6;
  }
  
  .step-badge {
    margin-right: 20px; /* Space between badge and content */
    flex-shrink: 0; /* Prevents the badge from shrinking */
    font-size: 1.25rem; /* Larger font size for better visibility */
    width: 40px; /* Fixed width for the badge */
    height: 40px; /* Fixed height for the badge */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Make the badge round */
    color: #fff; /* White text color */
    background-color: #007bff; /* Badge background color */
    position: absolute; /* Badge positioned absolutely */
    left: -15px; /* Offset the badge to the left */
  }
  
  .step-content {
    margin-left: 50px; /* Ensure space for the badge */
  }
  
  @media (max-width: 767px) {
    .step-wrapper {
      flex-direction: column; /* Stack items vertically on small screens */
    }
  
    .step-item {
      margin: 0 0 25px 0; /* Margin between items on small screens */
      padding: 15px; /* More padding for mobile */
      border: 1px solid #e0e0e0; /* Border around each step */
    }
  
    .step-badge {
      margin-right: 0;
      margin-bottom: 15px; /* Margin below badge for mobile */
      position: static; /* Badge positioned in the flow for mobile */
    }
  
    .step-content {
      margin-left: 0; /* No margin on mobile */
    }
  }
  
  /* // console.log("sdfsd") */