@import "../../variables/variables.scss";

.dropdown {
  background-color: red !important;
  position: absolute !important;
  // top: 0 !important;
  bottom: 0 !important;
  z-index: 1500 !important;
}

.nav-search {
  position: relative;

  .search-options {
    position: absolute;
    top: 40px !important;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: left;
    padding: 0 !important;
    z-index: 999;
    height: 200px;
    overflow-y: auto;

    li {
      list-style-type: none;
      padding-bottom: 5px;
      padding: 10px;
      font-size: 15px;

      &:hover {
        background-color: #eeeeee;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      }
    }
  }
}
