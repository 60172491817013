@import "../../variables/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.keep-up {
  background-color: $primary;
  padding: 2rem !important;
  color: white;
  border-bottom: 0.1px solid #ffffff66;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left {
    .keep-up-title {
      font : {
        weight: 600;
        size: 25px;
      }
    }

    .keep-up-content {
      font : {
        weight: 300;
        size: 14px;
      }
    }
  }

  .plant {
    position: absolute;
    right: 40%;
    top: 0;
    height: 90px;
  }

  .right {
    float: right !important;
    display: flex;
    align-items: center;

    .icon {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: white;
      height: 41px;
      width: 45px;
      @include d-flex;
      justify-content: center;

      .mail-icon {
        font-size: 18px;
        color: black;
      }
    }

    .keep-up-input {
      padding: 10px 0px;
      border: none;
      outline: none;
      color: gray;
      font-size: 14px;
      width: 220px;
    }

    .button-wrapper {
      background-color: white;
      padding: 5px 0px;
      padding-right: 5px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      .keep-up-button {
        padding: 5px 13px;
        border: none;
        background-color: #f04b25;
        font-size: 14px;
        color: white;
        transition: 0.2s;
        border-radius: 20px;
      }
    }
  }

  @media (max-width: 425px) {
    padding: 2rem 10px !important;

    .left {
      .keep-up-title {
        font-size: 20px;
      }
      .keep-up-content {
        font-size: 12px;
      }
    }
    .right {
      .icon {
        height: 34px;
        width: 35px;
      }
      .keep-up-input {
        padding: 8px 0px !important;
        font-size: 12px;
        width: 150px !important;
      }
      .button-wrapper {
        padding: 3.3px;
        padding-right: 4px;
        .keep-up-button {
          font-size: 11px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    text-align: center !important;
    .left {
      text-align: center !important;
      width: 100%;
    }
    .right {
      justify-content: center !important;
      width: 100% !important;
    }
  }
}
