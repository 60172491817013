@import "../../variables/variables.scss";


.error-icon{
    font-size: 60px;
    color: rgb(255, 80, 80);
    font-weight: bold;
}
.error-button{
    padding: 5px 10px;
    color: white;
    font-size: 12px;
    background-color: $primary;
    border: none;
    border-radius: 5px;
    margin: 10px;
    &:hover{
        background-color: $primary_hover_color;
    }
}