@import "../../../variables/variables.scss";

.deal-of-day-container {
  background-color: $bg_body_color;

  .slick-dots{
    display: block !important;
  }

  .deal-of-day-title {
    color: $primary;

    font : {
      weight: 600;
      size: 30px;
    }
  }

  .deal-card {
    @include card-common-styles;
    padding: 20px;
    color: black;
    
    flex: 1 1 21rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .card-image-wrapper {
      @include card-image-wrapper;
    }

    .deal-card-image {
      width: 100% !important;
    }

    .deal-card-content {
      flex-grow: 1;
      text-align: center !important;
      margin-top: 10px;

      .product-partnumber {
        font : {
          size: 15px;
          weight: 600;
        }
      }

      @include d-flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .card-price {
        @include card-price;
      }
    }

    .progress-bar {
      @include d-flex;
      justify-content: center;
      margin: 10px 0px;
      width: 100%;

      span {
        font : {
          size: 14px;
          weight: 600;
        }
        padding-bottom: 5px;
      }

      .progress {
        width: 236px !important;
        height: 8px;
        --bs-progress-bar-bg: #1aba1a !important;
        background-color: #e2e4eb;

        .progress-bar-animated {
          color: #1aba1a !important;
        }
      }
    }

    .deal-time {
      text-align: center;
    }

    .deal-time-container {
      @include d-flex;
      justify-content: center;
      margin: 5px 0px;
      text-align: center;
      width: 100%;

      .deal-time-part {
        margin: 5px;

        p {
          width: 46px;
          height: 44px;
          background-color: #e2e4eb;
          border-radius: 5px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          font : {
            weight: 600;
            size: 16px;
          }
        }

        span {
          font : {
            weight: 700;
            size: 12px;
          }
          color: #666666 !important;
        }
      }
    }

    .image-wrapper {
      @include card-image-wrapper;
    }
  }

  .slick-prev,
  .slick-next {
    color: black !important;
    background-color: $primary !important;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    z-index: 999 !important;

    @media (min-width: 425px) {
      display: block !important;
    }

    @media (min-width: 992px) {
    }
  }

  .slick-prev {
    margin-left: 30px;
  }

  .slick-next {
    margin-right: 30px;
  }

  .slick-slide {
    // margin: 5px !important;
    padding: 0 10px;
  }
}



.deal-of-day-container{

  .slick-cloned{
    display: none !important;
  }
}


.progress-bar{
  margin: 0px !important;
}

[role="progressbar"]{
  color: black;
}