@import "../variables/variables.scss";

.px-2r {
  padding: 0 2rem !important;

  @media (max-width: 768px) {
    padding: 20px !important;
  }
}

// chatbot
#bp-web-widget {
  z-index: 999 !important;
  right: 20px !important;
}

#bpw-floating-button {
  background-color: red !important;
}

.results-products-wrapper {
  .products {
    @media (min-width: 1200px) {
      gap: 20px !important;

      .result-card {
        width: 24% !important;
      }
    }
  }
}
.products-heading-container h4 {
  text-align: center !important;
}
 
.products-wrapper {
  background-color: $bg_body_color;

  .products-heading-container {
    position: relative;
   
    .view-all-products {
      position: absolute;
      top: 0%;
      right: 0;
      text-decoration: none;
      color: white;
      font : {
        size: 15px;
        weight: 500;
      }
      a {
        color: white;
        text-decoration: none;
      }

      &:hover {
        color: $primary_hover_color;
      }
    }

    .product-heading {
      font : {
        size: 20px;
        weight: 600;
      }
    }
  }

  .button-border {
    border: 1.5px solid $primary;
  }

  .button-hover {
    background-color: $primary;
    color: white;
    transition: 0.2s;
  }

  .products {
    width: 100%;
    display: flex;
    flex-wrap: wrap !important;

    .add-poster {
      width: 39% !important;
      margin-top: 10px;

      img {
        object-fit: cover;
        width: 100% !important;
        height: 100% !important;
        border-radius: 10px;
      }
    }

    .card {
      @include card-common-styles;

      .card-title {
        @include card-title;
      }

      .card-image-wrapper {
        @include card-image-wrapper;
      }

      .card-content {
        @include card-content;
      }

      .cart-footer {
        @include cart-footer;

        .card-price {
          @include card-price;
        }

        .card-add-cart-button {
          @extend .button-border;

          @include card-add-cart-button;

          &:hover {
            @extend .button-hover;
          }
        }
      }

      &:hover {
        @include card-hover;
      }
    }

    @media (min-width: 1200px) {
      gap: 20px;

      .card {
        width: 18.5% !important;
      }

      // .result-card {
      //   width: 23.4% !important;
      // }
    }

    @media (max-width: 1200px) {
      gap: 19px;
      justify-content: center !important;

      .card {
        width: 19% !important;
      }
    }

    @media (max-width: 1200px) {
      .add-poster {
        display: none;
      }
    }

    @media (max-width: 992px) {
      gap: 10px;

      .card {
        width: 30% !important;
      }
    }

    @media (max-width: 600px) {
      gap: 10px;

      .card {
        width: 40% !important;
      }
    }

    @media (max-width: 425px) {
      gap: 10px;

      .card {
        .card-title {
          font: {
            size: 13px;
            weight: 500;
          }
        }

        .card-image {
          height: 100px !important;
        }

        .current-price {
          font: {
            size: 14px !important;
            weight: 500 !important;
          }
        }

        .old-price {
          font: {
            size: 11px !important;
            weight: 400 !important;
          }
        }

        .card-add-cart-button {
          padding: 2px 10px !important;

          font: {
            size: 13px !important;
          }
        }

        .card-menu {
          font-size: 42px !important;
        }
      }

      .add-poster {
        display: block;
        height: auto !important;
        width: 100% !important;
      }
    }

    @media (max-width: 375px) {
      .card {
        width: 100% !important;
      }
    }
  }

  .loadmore-button button {
    padding: 5px 20px;
    color: $primary;
    background-color: white;
    margin-top: 40px;
    border-radius: 5px;
    @extend .button-border;

    &:hover {
      @extend .button-hover;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.quantity-input-group {
  display: flex !important;

  .quantity-input {
    border: 0.5px solid black !important;
    border-right: none !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 30px !important;
  }

  .quantity-button-group {
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      border: none;
      background-color: white;
      padding: 4px;
    }

    :nth-child(1) {
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-top-right-radius: 5px;
    }

    :nth-child(2) {
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      padding: 0px 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.content {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 1s ease, height 1s ease;
}

.content.show {
  opacity: 1;
  height: auto;
}

.content.hide {
  opacity: 0;
  height: 0;
  transition: 1s;
}

.add-poster
  > .image-gallery
  > .image-gallery-content
  > .image-gallery-slide-wrapper
  > .image-gallery-swipe
  > .image-gallery-slides {
  border-radius: 15px;
  height: 80%;
}

svg > [clip-path="url(#__lottie_element_2)"] > g > g > path {
  fill: rgb(4, 67, 192) !important;
}
svg > [clip-path="url(#__lottie_element_2)"] > g > g > g > g > path {
  fill: rgb(4, 67, 192) !important;
}

//aboutus
/* Ensure the container hides any overflowing content */
.MuiCardActionArea-root,
.card-image-wrapper,
.image-container,
.products-slide {
  overflow: hidden;
  position: relative; /* Ensure the container positions are relative */
}

/* Apply styles to the image */
.MuiCardMedia-img,
.card-image-wrapper img,
.image-container,
.products-slide {
  width: 100%;
  height: 100%; /* Or set a specific height if needed */
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.4s ease, opacity 0.4s ease;
}

/* Hover effect on image */
.MuiCardActionArea-root:hover .MuiCardMedia-img,
.image-container:hover,
.products-slide:hover {
  transform: scale(1.05);
  opacity: 0.8;
}
.card-image-wrapper:hover img {
  transform: scale(1.09);
}
.Vision-and-Values {
  padding-bottom: 60px;
}
.image-container img {
  width: 98% !important;
}
// Responsive

@media screen and (max-width: 768px) {
  .requirements h3 {
    font-size: 25px !important;
  }
  .about-history {
    display: block !important;
  }
  .image-container img {
    height: 220px !important;
  }
  .MuiTypography-root {
    font-weight: 600;
  }
  .sticky-header {
    display: flex;
    .navbar {
      display: flex !important;
      padding: 0px !important;
      column-gap: 10px;
    }
  }
  .navbar .brand-logo {
    width: 100px !important;
  }
}
@media ( max-width:320px) {
  .products-heading-container   h4{
    font-size: 14px !important;
    width: 65%;
    text-align: start !important;

  }
}
@media ( max-width:554px) {
  .products-heading-container   h4{
    font-size: 14px !important;
    width: 65%;
    text-align: start !important;

  }
}
@media ( max-width:768px) {
  .products-heading-container   h4{
    font-size: 14px !important;
    width: 65%;
    text-align: start !important;

  }
}