$primary: #0443c0;
$primary_hover_color: #0b5ed7;
$primary_dark: #07389a;
$bg_body_color: #f7fafc;
$gray: #929dab;
$lightGray: #f2f4f6;
$mediumGray: #b6b4b4;
$darkGray: #e9e9e9;

@mixin d-flex {
  display: flex;
  align-items: center;
}

@mixin container {
  padding: 0rem 2rem !important;
}

@mixin card-common-styles {
  background-color: white;
  overflow: hidden;
  position: relative;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: #cccccc 0px 1px 3px 0px, #cccccc 0px 0px 0px 1px;
}

@mixin card-title {
  font: {
    size: 16px;
    weight: 600;
  }
  margin-top: 20px;
}

@mixin card-image-wrapper {
  text-align: center;

  .card-image {
    width: 70%;
    height: 173px;
    object-fit: contain !important;
  }
}

@mixin card-content {
  position: absolute;
  bottom: 0;
  top: 0;
  right: -100%;
  transition: 0.4s;
  .card-menu {
    background-color: $primary;
    color: white;
    font-size: 45px;
    margin-bottom: 5px;
    padding: 12px;
    &:hover {
      background-color: red;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

@mixin card-add-cart-button {
  padding: 5px 20px;
  margin-top: 13px;
  border-radius: 5px;
  background-color: white;
  color: $primary;
  transition: 0.2s;

  font : {
    weight: 500;
    size: 14px;
  }
}

@mixin cart-footer {
  margin-bottom: 15px;
  margin-top: 30px;
}

@mixin card-price {
  @include d-flex;
  justify-content: center;

  .current-price {
    color: $primary;
    font : {
      size: 17px;
      weight: 600;
    }
    // padding: 0px 10px;
  }

  .old-price {
    font : {
      size: 13px;
      weight: 600;
    }
    color: $gray;
    text-decoration: line-through;
    margin-left: 5px !important;
  }
}

@mixin card-hover {
  .card-content {
    right: 0 !important;
    transition: 0.4s;
  }
}
