@import "../../variables/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.contact-section {
  padding: 2rem !important;
  --bs-gutter-x: 0rem !important;
  background-color: $bg_body_color;

  .contact-box {
    color: black;
    display: flex;
    margin: 10px 0px;

    .contact-icon {
      color: $primary;
      margin: 0px 20px;

      font : {
        size: 20px;
        weight: 900;
      }
    }

    p {
      margin: 0;
      font : {
        size: 15px;
        weight: 400;
      }
      text-transform: uppercase;
    }
  }

  @media (min-width: 768px) {
    .contact-box-0,
    .contact-box-1,
    .contact-box-2 {
      border-right: 2px solid $mediumGray;

      &:hover {
        border-right: 2px solid $primary;
      }
    }
  }
}
