.product-images-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .image-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }
  .row-container .image-gallery-image {
    /* box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important; */
    height: 28vh !important;
}