.list-style-none {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
}

.NoWishlistlists > .NoWishlistImage {
  width: 30vw !important;
  height: 50% !important;
}


.listProducts-banner{

  background-color: #e0e0e0;
  background-image: url("../../Assets/Best inida kart.jpg");
  background-size: cover; /* Ensures the image fits within the container */
  background-position: cover; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
  h2{
    font-size: 50px;
  }
}

.listProducts-banner>h2{
  font-size: 50px;
}


@media (max-width:768px) {
  
  .listProducts-banner{
    height: 100px !important;
  }
  .listProducts-banner>h2{
    font-size: 30px !important;
  }
}

@media (max-width:426px){



  .NoWishlistlists > .NoWishlistImage {
    width: 50vw !important;
    height: 50% !important;
  }
  .no-wishlist-content{
    font-size: medium;
  }
}