@import "../../variables/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.row-container {
  padding: 2rem !important;
  color: white;
  margin: 0 !important;

  .tab-content .show {
    width: 100% !important;
  }
  .nav-tabs {
    border: none !important;
    .nav-item {
      margin-right: 10px;
      .nav-link {
        border: none !important;
        padding: 9px 25px !important;
        margin: 10px 0px;
        background-color: $primary;
        color: white;
        border: none;
        font-size: 15px;
        transition: 0.2s;
        margin-right: 10px;
        transition: 0.3s;
        width: 100% !important;
        border-radius: 0px;

        &:hover {
          background-color: #07389a;
          transition: 0.3s;
        }
      }
    }
  }

  .detail-section-content {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
    overflow: hidden;

    .detail-products-image {
      width: 100%;
      max-height: 300px;
      object-fit: contain;
    }

    @media (max-width: 992px) {
      .detail-products-image {
        width: 100%;
      }
    }
  }

  .image-gallery-image {
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    // height: 75vh !important;
  }

  .detail-section {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
    .detail-image {
      object-fit: cover !important;
      width: 100% !important;
      height: 70vh;
    }
    .detail-content {
      color: black;
      margin: 0px 30px;
      .detail-content-title {
        font-weight: 700;
      }
      .detail-content-para {
        font-weight: 500;
        margin: 0;
      }
      .detail-content-price {
        font-weight: 700;
        padding: 20px 0px;
        color: #f04b25 !important;
      }

      .quantity-input {
        width: 40px;
        margin-left: 10px;
        border: none;
        outline: none;
        border-radius: 2px;
        text-align: center;
      }

      .add-to-buttons {
        button {
          padding: 9px 20px;
          margin: 10px 0px;
          border: 0.1px solid black;
          font-size: 13px;
          transition: 0.2s;
          .add-to-icon {
            font-size: 20px;
            transition: 0.1s;
            margin-left: 15px;
            background-color: transparent;
            path {
              color: black !important;
            }
          }

          &:hover {
            color: white;
            transition: 0.2s;
            background-color: $primary;
            .add-to-icon {
              transition: 0.1s;
              background-color: transparent;
              path {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }
  .table th,
  td {
    border: 1px solid #dddddd;
    border-collapse: collapse;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #f6fafd;
  }

  .review-content {
    background-color: #e9edf0;
    height: 300px;
    padding: 20px;
    overflow-y: auto !important;

    .self-comments {
      img {
        width: 30px;
        border-radius: 50%;
      }

      input {
        border: none;
        outline: none;
        border-bottom: 1px solid gray;
        margin-left: 20px;
        background-color: #e9edf0;
        width: 100%;
      }
      .submit-buttons {
        padding-top: 10px;
        :nth-child(1) {
          background-color: white;
          color: black;
          transition: 0.3s;

          &:hover {
            background-color: $primary;
            color: white;
            transition: 0.3s;
          }
        }

        .review-submit {
          background-color: $primary;
          padding: 5px 10px;
          border-radius: 15px;
          margin: 5px;
          font-size: 13px;
          border: none;
          color: white;
          &:hover {
            background-color: #07389a;
          }
          .submit-icon {
            background: none !important;
            margin-left: 5px;
            font-size: 15px;
            path {
              color: white;
            }
          }
        }
      }
    }
    .users-comments {
      padding: 20px 0px;

      img {
        width: 35px;
        border-radius: 50%;
      }
      h6 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 !important;

    .detail-content {
      padding: 20px 0px;
    }
  }
}
.related-product {
  margin: 20px 0px !important;
  padding-left: calc(var(--bs-gutter-x) * 0) !important;
  padding-right: calc(var(--bs-gutter-x) * 0);
  color: white;

  .related-product-container {
    padding: 0rem 2rem;
    .related-product-heading {
      background-color: $primary;
      padding: 20px 0px;
      span {
        padding: 20px;
        margin-bottom: 0px;
        &:hover {
          background-color: #07389a;
        }
      }
    }
  }

  .card {
    @include card-common-styles;

    .card-title {
      @include card-title;
    }

    .card-image-wrapper {
      @include card-image-wrapper;
    }

    .card-content {
      @include card-content;
    }

    .card-add-cart-button {
      border: 1.5px solid $primary;
      @include card-add-cart-button;
      margin-bottom: 10px;

      &:hover {
        background-color: $primary;
        color: white;
        transition: 0.2s;
      }
    }

    .card-price {
      @include card-price;
    }

    .card-content {
      :nth-child(1) {
        // background-color: red !important;
      }
    }

    &:hover {
      @include card-hover;
    }
  }

  .tab-content {
    .show {
      width: 100% !important;
    }
  }

  .slick-slide {
    div {
      margin-left: 20px;
    }
  }
}
.image-gallery .image-gallery-slide-wrapper .image-gallery-right-nav, .image-gallery .image-gallery-slide-wrapper .image-gallery-left-nav{
  display: none !important;
}
 ::backdrop{
  display: none !important;
}
.image-gallery-content.fullscreen {
 
  margin: auto;
  background: rgba(0, 0, 0, 0.295) !important;
 width: 100%;
 height: 100%;
}
button.image-gallery-icon.image-gallery-play-button {
  display: none;
}
button.image-gallery-icon.image-gallery-fullscreen-button{
  display: none;
}