* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.modal-header {
  padding: 10px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}
.over-view-container{
  overflow: hidden;
  .product-overview-image {
    overflow: hidden;
    height: 300px;
    // box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
    border-radius: 10px;
  
    img {
      object-fit: contain !important;
      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }
  .product-overview-content{
    height: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  
    .price{
      color: #f04b25 ;
    }
  
    label{
      min-width: 90px !important;
      font-size: 15px;
      font-weight: 600;
    }
    span{
      padding-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  
    .buying-buttons{
      display: flex;
      align-items: center;
      margin-top: auto;
      input{
        margin-right: 10px;
        padding: 5px;
        width: 70%;
      }
      button{
        padding: 7px;
        color: white;
        background-color: #f04b25;
        width: 100%;
        border: none;
        &:hover{
          background-color: #D9261C;
        }
      }
    }
  }
}
