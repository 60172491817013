@import "../../variables/variables.scss";

.copy-right-page {
  background-color: #ebeef6;
  padding: 20px;
  @include d-flex;
  justify-content: space-between;

  a {
    color: black;
    text-decoration: none;
    font : {
      weight: 600;
      size: 16px;
    }
  }

  .payment-method {
    @include d-flex;
    flex-wrap: wrap;

    img {
      width: 46px;
      height: 18px;
      object-fit: contain;
      margin-left: 10px;
    }
  }

  @media (max-width: 992px) {
    justify-content: center;

    .payment-method {
      display: none;
    }
  }

  @media (max-width: 768px) {
    font-size: 13px !important;
  }
}
