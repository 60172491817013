/* .card-stepper {
  z-index: 0;
}
.step-label1{
margin-top: 40%;
}
#progressbar-2 {
  color: #455A64;
}

#progressbar-2 li {
  list-style-type: none;
  font-size: 13px;
  width: 16.66%; 
  float: left;
  position: relative;
  text-align: center;
}

#progressbar-2 li:before {
  content: '\f058';
  font-family: "Font Awesome 5 Free";
  color: #fff;
  width: 37px;
  height: 37px;
  line-height: 37px;
  display: block;
  font-size: 16px;
  background: #c5cae9;
  border-radius: 50%;
  margin: auto;
  transition-timing-function: linear;
}

#progressbar-2 li:after {
  content: '';
  width: 16.66%; 
  height: 10px;
  background: #c5cae9;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 15px;
  z-index: -1;
  transition: width 3s;
  transition-delay: 1s;
}

#progressbar-2 li:nth-child(1):after {
  left: 40%;
  width: 100%;
  transition: width 2s;
}

#progressbar-2 li:nth-child(2):after {
  right: 40%;
  left: 40.66%;
  width: 100%;
}

#progressbar-2 li:nth-child(3):after {
  left: 40.33%;
  width: 100%;
}

#progressbar-2 li:nth-child(4):after {
  left: 50%;
  width: 100%;
}

#progressbar-2 li:nth-child(5):after {
  left: 66.66%;
  width: 100%;
}


#progressbar-2 li.active:before,
#progressbar-2 li.active:after {
  background: #6520ff;
}

.step-label {
  margin-top: 5px;
  font-size: medium;
} */
/* .card-stepper {
  z-index: 0;
}

.step-label1 {
  margin-top: 40%;
}

#progressbar-2 {
  color: #455A64;
  position: relative;
  margin: 0;
  padding: 0;
}

#progressbar-2 li {
  list-style-type: none;
  font-size: 15px;
  width: 16.66%;
  float: left;
  position: relative;
  text-align: center;
}

#progressbar-2 li:before {
  content: '\f058';
  font-family: "Font Awesome 5 Free";
  color: #fff;
  width: 37px;
  height: 37px;
  line-height: 37px;
  display: block;
  font-size: 16px;
  background: #c5cae9;
  border-radius: 50%;
  margin: auto;
  transition-timing-function: linear;
}

#progressbar-2 li:after {
  content: '';
  width: 0; /* Start with zero width */
  /* height: 10px;/ */
  /* background: #c5cae9;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: -1;
  transition: width 1s ease-in-out; 
}

#progressbar-2 li.active:before,
#progressbar-2 li.active:after {
  background: #6520ff;
}

.step-label {
  margin-top: 5px;
  font-size: large;
}

#progressbar-2 li:first-child:after {
  width: 100%; 
}

#progressbar-2 li.active:after {
  background: #6520ff;
}  */

@media (max-width:420px) {
  .step-label{
    display: none;
  }
}
 
.card-stepper {
  z-index: 0;
}

.step-label1 {
  margin-top: 40%;
}

#progressbar-2 {
  color: #455A64;
  position: relative;
  margin: 0;
  padding: 0;
}

#progressbar-2 li {
  list-style-type: none;
  font-size: 15px;
  width: 16.66%;
  float: left;
  position: relative;
  text-align: center;
}

#progressbar-2 li:before {
  content: '\f058';
  font-family: "Font Awesome 5 Free";
  color: #fff;
  width: 37px;
  height: 37px;
  line-height: 37px;
  display: block;
  font-size: 16px;
  background: #c5cae9;
  border-radius: 50%;
  margin: auto;
  transition-timing-function: linear;
}

#progressbar-2 li:after {
  content: '';
  width: 0;
  height: 10px;
  background: #c5cae9;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: -1;
  transition: width 1s ease-in-out;
}

#progressbar-2 li.active:before,
#progressbar-2 li.active:after {
  background: #6520ff;
}

.step-label {
  margin-top: 5px;
  font-size: large;
}

#progressbar-2 li:first-child:after {
  width: 100%;
}

#progressbar-2 li.active:after {
  background: #6520ff;
}

.dssd {
  border: none !important;
}

/* Table Styles */
.ddd {
  width: 100%;
  /* border-collapse: collapse; */
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border:1px solid rgba(0, 0, 255, 0.37);
   /* border-radius:12px ; */
}

.ddd th,
.ddd td {
  padding: 12px 15px;
  /* border: 1px solid #ddd; */
}

.ddd thead th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.ddd tbody tr {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.ddd tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

/* .ddd tbody tr:hover {
  background-color: #f1f1f1;
} */

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ddd {
    font-size: 14px;
  }

  .ddd th,
  .ddd td {
    padding: 10px 12px;
  }
}

@media (max-width: 576px) {
  .ddd {
    font-size: 12px;
  }

  .ddd th,
  .ddd td {
    padding: 8px 10px;
  }
}
