.privacy-section{
    background: linear-gradient(
        212deg,
        rgba(83, 120, 149, 1) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      padding-top: 40px;
      padding-bottom: 40px;
}


.privacy-policy-wrapper {
    padding: 40px !important;
    // background-color: #95D2B3;
    background-color: white;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important; /* Replace 'Arial' with your desired font family */
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
    margin-bottom: 10px;
    border-radius: 10px;

  ul > li {
    font-size: medium;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }



}

.privacypolicy-page-heading {
    margin-top: 10px;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: 600;
    // color: #0443c0;
    color: #2f3645;
    background: linear-gradient(
      210deg,
      rgba(206, 211, 212, 1) 0%,
      rgba(245, 246, 246, 1) 56%,
      rgba(168, 176, 178, 1) 100%
    );
    padding: 20px;
    border-radius: 8px;
  }


  .privacy-policy-content>p{
    margin-left: 18px;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .privacy-policy-content>span{
    padding-left:18px ;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .privacy-policy-content>h3{
    font-weight: 600;
  }