@import "../../variables/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 4px;
  
}
::-webkit-scrollbar-thumb {
  background-color: $primary !important;
}

.nav-link {
  padding: 8px 0px !important;
  margin-left: 5px;
}
.slick-arrow {
  display: none !important;
}
.slick-dots {
  display: none !important;
}

.requirements {
  background-color: $primary;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .plant-image {
    position: absolute;
    width: 333px;
    height: 90px;
    right: 25%;
    top: 10px;
    object-fit: contain;
  }

  h3 {
    font-weight: 600;
    font-size: 27px;
  }
  p {
    font-weight: 200;
    font-size: 20px;
  }

  .button {
    background-color: #f04b25;
    border: navajowhite;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font : {
      weight: 500;
    }
    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    }
  }
}

@media (max-width: 768px) {
  .row-two {
    padding: 0px !important;
  }
}
