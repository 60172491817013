@import "../../variables/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer {
  padding: 2rem 1rem !important;
  background-color: $bg_body_color;

  .footer-title {
    margin-top: 10px;
    font : {
      weight: 700;
      size: 16px;
    }
  }

  .sub-title {
    font : {
      weight: 500;
      size: 13px;
    }
  }

  .contact-number {
    color: $primary;
    font : {
      weight: 700;
      size: 20px;
    }
  }

  .social-media {
    @include d-flex;
    span {
      border: 1px solid $gray !important;
      font-size: 18px !important;
      margin-right: 15px !important;
      border-radius: 20px !important;
      padding: 5px 10px !important;

      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
      }
    }
  }

  img {
    width: 150px;
    margin-top: -25px !important;
  }

  .links-container {
    margin-top: 20px;
    .footer-links {
      color: #666666;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400 !important;

      p {
        margin: 8px 0px !important;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  .footer-small {
    @media (max-width: 575px) {
      width: 50% !important;
    }
  }
}
.accept-to-card {
  font-weight: 700 !important;
  font-size: 15px;
  color: black;
  text-decoration: none;
}