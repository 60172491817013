@import "../../variables/variables.scss";

.listProducts {
  .listProducts-products {
    margin-top: 50px !important;
    .table {
      .product-remove,
      .product-thumbnail-title {
        opacity: 0 !important;
      }
      .product-thumbnail {
        width: 100px;
      }

      tbody {
        .product-data {
          height: 100%;
          padding-top: 3rem !important;
          position: relative;

          input {
            text-align: center !important;
            width: 0px !important;
          }

          .input-group-quantity {
            display: flex;
            align-items: center;

            :nth-child(1) {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }

            :nth-child(2) {
              border-radius: 0px;
              width: 50px !important;
            }

            :nth-child(3) {
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
            }
          }
        }

        .product-container {
          display: flex;
          border-bottom: 2px solid $gray;
          padding: 20px 0px;

          .product-content {
            .product-content-row {
              display: flex;
              justify-content: space-between;
              padding: 10px 0px;
              border-bottom: 2px solid $gray;

              .input-group-quantity {
                display: flex;

                input {
                  border: 1px solid black;
                  border-right: none;
                  border-bottom-left-radius: 5px;
                  border-top-left-radius: 5px;
                }

                .quantity-button-group {
                  display: flex;
                  flex-direction: column;

                  button {
                    padding: 2px 4px;
                  }

                  :nth-child(1) {
                    background-color: white;
                    border: none !important;
                    border-top: 1px solid black !important;
                    border-right: 1px solid black !important;
                    border-top-right-radius: 5px;
                  }

                  :nth-child(2) {
                    background-color: white;
                    border: none !important;
                    border-bottom: 1px solid black !important;
                    border-right: 1px solid black !important;
                    border-bottom-right-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-collaterals {
  border: 5px solid #ebebeb;
  .cart_totals {
    padding: 10px 20px;
    border: 2px solid #ebebeb;
    .cart-row {
      border-bottom: 2px solid #ebebeb;
    }
    h6 {
      font-weight: 800;
      font-size: 18px;
    }
    .shop_table {
      font-weight: 700;
      .shop-title {
        font-weight: bold;
        font-size: 15px;
      }
      .shop-content {
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          color: red;
          font-weight: 500 !important;
        }
      }
      .shop-subtotal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
          font-weight: 600;
        }
      }
      // .shipping {
      //   .shipping-details {
      //     .change-password {
      //       margin-top: 10px !important;
      //       cursor: pointer;
      //       &:hover {
      //         color: red;
      //       }
      //     }
      //     .shipping-calculator-form {
      //       text-align: center !important;
      //       position: absolute;
      //       background-color: $gray;
      //       border-radius: 10px;
      //       padding: 20px;
      //       left: -100px !important;
      //       border: 1px solid black;

      //       button {
      //         padding: 5px 20px;
      //         background-color: $primary;
      //         color: white;
      //         border: none;
      //         outline: none;
      //         border-radius: 5px;
      //         &:hover {
      //           background-color: $primary_hover_color;
      //         }
      //       }
      //     }
      //   }
      // }
    }
    .checkout-button {
      width: 100% !important;
      background-color: $primary;
      border-radius: 2px;
      border: none;
      outline: none;
      padding: 8px;
      text-align: center;

      :nth-child(1) {
        color: white;
        text-decoration: none;
      }

      &:hover {
        background-color: $primary_hover_color;
      }
    }
  }
  @media (max-width: 992px) {
    margin-top: 20px !important;
  }
}



// .listProducts-banner {
//   height: 200PX;
//   background-color: #e0e0e0;
//   // opacity: 2px;
//   // background-color: transparent(2px) !important ;
//   background-image: url("../../Assets/Best\ inida\ kart.png");
//   object-fit: contain;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
// }
.listProducts-banner{
  height: 200PX;
  background-color: #e0e0e0;
  background-image: url("../../Assets/Best inida kart.jpg");
  background-size: cover; /* Ensures the image fits within the container */
  background-position: cover; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
  h2{
    font-size: 50px;
  }
}


// g>.ai{
//   display: none;
// }

svg>g>g:nth-child(10){
  display: none !important;
}