@import "../../variables/variables.scss";

.search-container {
  @include container;
  margin-right: calc(0 * var(--bs-gutter-x)) !important;
  margin-left: calc(0 * var(--bs-gutter-x)) !important;

  display: flex;
  background-color: $bg_body_color;
  

  @media (max-width: 768px) {
    .filters {
      display: none;
    }
  }
}
