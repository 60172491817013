@import "../../variables/variables.scss";

.border-right {
  border-right: 1px solid #ebebeb !important;
}
.wishlist-products {
  border: 0.5px solid #ebebeb !important;
  padding-bottom: 10px;
  width: 100%;
  


  img {
    width: 130px !important;
    
    object-fit: cover;
  }
  .wishlistProducts-name {
    color: $primary;
    font-weight: 600;
    font-size: 20px;
  }
  .wishlistProductsbButton {
    background-color: $primary;
    border-radius: 2px;
    border: none;
    outline: none;
    padding: 8px 20px;
    color: white;
    border-radius: 3px;
    text-align: center;
    margin-left: 20px;
    &:hover{
        background-color: $primary_hover_color;
    }
  }
}

@media (min-width:720px) {

    .wishlist-heading{
      width: 100vw;
      height: 2vh;
    }


}

