@import "../../variables/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.header {
  background: $primary;
  @include d-flex;
  @include container;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 30px;
  
  // margin-bottom: 10px;

  .header-text {
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .nav-content {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      margin-left: auto !important;
    }

    .nav-icon {
      width: 20px;
      height: 20px;
      margin: 0px 10px;
    }

    .nav-title {
      font: {
        weight: 400;
        size: 12px;
      }
    }

    .nav-number {
      font: {
        weight: 600;
        size: 14px;
      }
    }
  }
  .icon-blue {
    color: blue !important;
  }
  .auth-an-cart-status-group-rsponsive {
    @include d-flex;
    margin-bottom: 0 !important;
    margin-right: 12px;

    li {
      list-style-type: none;
      margin-left: 20px;
      font-size: 14px;
      font-weight: 500;

      a,
      span {
        text-decoration: none;
        color: white;
      }
    }

    .auth-status {
      span {
        margin: 0px 5px;
        font-size: 14px;
      }
    }

    .cart-status {
      position: relative;
      .navbar-icon {
        height: 25px;
        width: 25px;
      }

      .badge {
        background-color: white;
        color: $gray;
        position: absolute;
        font-size: 9px !important;
        right: -10px;
        border-radius: 50%;
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
    @media (max-width: 768px) {
      .auth-status {
        span,
        a {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 650px) {
    padding: 8px 10px !important ;
  }

  @media (max-width: 768px) {
    height: 54px;

    .header-text {
      font-size: 11px;
    }

    .nav-title,
    .nav-number {
      display: none;
    }

    .nav-icon {
      width: 25px !important;
      height: 25px !important;
    }
  }
  @media (max-width: 425px) {
    .nav-content {
      justify-content: center;
    }

    .auth-an-cart-status-group-rsponsive {
      li {
        margin-left: 15px !important;
      }
    }
  }
}

.navbar {
  @include d-flex;
  @include container;
  justify-content: space-between !important;
  font-family: poppins;
  top: -1px !important;
  text-align: center;

  .brand-logo {
    width: 150px;
  }

  .nav-search {
    text-align: center;
    margin-left: 20px;
    @media (min-width: 992px) {
      width: 50%;
    }
    width: 100%;

    .commom-style {
      border: 2px solid $primary;
    }

    .nav-input-search {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      font-size: 15px;
      color: $mediumGray;
      @extend .commom-style;
      background-color: $lightGray;
      border-right: 0 !important;
      padding: 5px 20px;
      outline: none;
      text-transform: capitalize;

      &:focus {
        color: black !important;
      }
    }

    .nav-input-button {
      padding: 5px 15px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      @extend .commom-style;
      background-color: $primary;
      color: white;

      .search-icon {
        width: 27px;
        height: 27px;
      }

      &:hover {
        background-color: $primary_hover_color;
      }
    }
  }

  .auth-an-cart-status-group {
    @include d-flex;
    padding-left: 0 !important;

    li {
      list-style-type: none;
      margin-left: 20px;
      font-size: 14px;
      font-weight: 500;

      a {
        text-decoration: none;
        color: $gray;
      }
    }

    .auth-status {
      span {
        margin: 0px 5px;
        font-size: 14px;
      }
    }

    .cart-status {
      position: relative;
      .navbar-icon {
        height: 31px;
        width: 31px;
      }
      .badge {
        background-color: $gray;
        position: absolute;
        font-size: 9px !important;
        top: -2px;
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .badge {
    left: 65%;
    font-size: 10px;
    border-radius: 50%;
  }

  @media (max-width: 650px) {
    display: block !important;
    .nav-search {
      margin: 20px 0px;

      .nav-input-search {
        font-size: 12px;
      }

      .nav-input-button {
        padding: 5px 10px;
      }
    }
  }
}

.sub-navbar {
  @include container;
  color: white;
  @include d-flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .shope-category {
    position: relative;
    .category-links {
      position: absolute;
      background-color: red;
    }
  }

  .sub-brand {
    background-color: $primary;
    padding: 12px 20px;
    position: absolute;
    left: 3%;
    top: 0;
    margin-top: 20px;
    font-size: 15px;
    border-radius: 10px 10px 0px 0px;
    @include d-flex;
  }

  .sub-navbar-menu {
    @include d-flex;
    justify-content: center;
    margin-bottom: 0 !important;

    li {
      list-style-type: none;
      padding: 11px;
      a {
        text-decoration: none;
        color: black;
        font: {
          weight: 500;
          size: 15px;
        }
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
  .offcanvas-sidebar {
    display: none;
  }

  .hambargure {
    &:hover {
      background-color: $lightGray;
      border-radius: 20px;
    }
  }

  @media (max-width: 1066px) {
    justify-content: end;

    .offcanvas-sidebar {
      display: block;
    }

    .sub-navbar-menu {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding: 0rem 1.5rem !important;
  }
}

.offcanvas-body {
  padding: 0 !important;
}
.offcanvas-sub-navbar-menu {
  padding-left: 0rem;
  li {
    list-style-type: none;
    padding: 20px;
    border: 1px solid $gray;

    a {
      text-decoration: none;
      color: black;
    }
    &:hover {
      background-color: $primary !important;

      a {
        color: white !important;
      }
    }
  }
}
// .sticky-top {
//   position: sticky;
//   top: 0;
//   z-index: 20 !important;
//   background-color: white;
// }


.sticky-header {
  position: -webkit-sticky; /* For older WebKit-based browsers */
  position: sticky;
  top: 0; /* Set this to the distance from the top of the viewport */
  background-color: #f8f9fa;
  z-index: 1000; /* Optional: ensure it's above other content */
  padding: 10px;
  box-shadow:rgb(255 255 255 / 0%) 0px 50px 100px -20px, rgb(231 223 223 / 0%) 0px 30px 60px -30px, rgb(10 37 64 / 18%) 0px -2px 6px 0px inset;
}

.auth-status.cart-status > .user.dropdown{
  background: transparent !important;
}


.user>.dropdown-toggle{
  
  background: transparent !important;
  display: inline !important;
  color: transparent !important;
  border: none !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

}

.sub-navbar .sub-navbar-menu li a {
  margin-left: 30px;
  font-weight: 600;
  color: black; 
  text-decoration: none; 
}

.sub-navbar .sub-navbar-menu li a.active,
.sub-navbar .sub-navbar-menu li a:hover {
  color: $primary !important;
}

.sub-navbar .sub-navbar-menu{
  padding-left: 10rem;
}