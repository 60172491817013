.products-slider {
  background-color: #f0f0f0; /* Light grey background */
  padding: 20px; /* Add some padding around the slider */
  border-radius: 8px; /* Rounded corners */
  margin: 20px 0; /* Add margin to the top and bottom */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 5 - 20px); /* Default width for desktop */
  height: calc(100vw / 4); /* Adjust height based on viewport width */
  margin: 0 10px; /* Add some margin between items */
  border: 2px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  background:#fff !important;
}

.image-container img {
  width: 100%; /* 100% width of the container */
  height: 70%; /* Adjust height for the images */
  object-fit: contain; /* Ensure the image covers the container */
  
}


.image-caption {
  margin-top: 10px;
  /* font-size: calc(1vw + 8px); Adjust font size based on viewport width */
  text-align: center;
  color: #333;
  word-wrap: break-word; /* Ensure long words break into the next line */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 700;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .image-container {
    width: calc(100% / 4 - 20px); /* Adjust width for large screens */
  }
}

@media (max-width: 768px) {
  .image-container {
    width: calc(100% / 3 - 20px); /* Adjust width for tablet devices */
  }
}

@media (max-width: 576px) {
  .image-container {
    width: calc(100% / 2 - 20px); /* Adjust width for small devices */
  }
}

@media (max-width: 480px) {
  .image-container {
    width: calc(100% - 20px); /* Adjust width for very small devices */
  }
}








































/* 



.products-slider {
  background-color: #f0f0f0; 
  padding: 20px; 
  border-radius: 8px; 
  margin: 20px 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / 5 - 20px);
  height: calc(100vw / 4); 
  margin: 0 10px;
}

.image-container img {
  width: 100%; 
  height: 70%; 
  object-fit: contain;
  border: 2px solid #ccc; 
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-caption {
  margin-top: 10px;
  font-size: calc(1vw + 8px);
  text-align: center;
  color: #333;
  word-wrap: break-word; 
}

@media (max-width: 1200px) {
  .image-container {
    width: calc(100% / 4 - 20px); 
  }
}

@media (max-width: 768px) {
  .image-container {
    width: calc(100% / 3 - 20px); 
  }
}

@media (max-width: 576px) {
  .image-container {
    width: calc(100% / 2 - 20px); 
  }
}

@media (max-width: 480px) {
  .image-container {
    width: calc(100% - 20px); 
  }
} */