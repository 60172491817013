@import "../../variables/variables.scss";

.login-page {
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-page-inner {
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    width: 400px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin: 20px;
    padding: 0px 20px;
    box-shadow: 0 7px 14px 0 rgba(95, 89, 99, 0.5),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }

  .login-logo {
    width: 160px !important;
    margin-bottom: 10px;
  }

  .right {
    width: 100%;

    .login-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;

      .login-title-icon {
        color: black;
        &:hover {
          color: $primary;
        }
      }

      h6 {
        font-size: 1rem;
        margin-bottom: 8px;
        text-align: center;
        font-weight: 700;
        font-size: 15px;
      }
    }

    .form-control {
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      border-radius: 0.3rem;
      outline: none;
      border-left: 4px solid $primary;
      margin: 15px 0px;
      padding: 12px;
      box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
      display: flex;
      align-items: center;

      .form-control-icon {
        font-size: 17px;
        margin-right: 10px;
        color: gray;
      }

      .form-control-input {
        outline: none;
        border: none;
        width: 100%;
      }

      button {
        border: none;
        background-color: white;
        .eye-icon {
          font-size: 20px;
          color: black;
        }
      }
    }

    .sub-items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      label {
        padding: 0px 5px;
        font-size: 11px;
        font-weight: 600;
        color: rgb(106, 106, 106);
        cursor: pointer;
      }
    }
    .forgot-password {
      font-size: 11px;
      font-weight: 600;
      color: $primary;
      text-decoration: none;
      &:hover {
        color: $primary_hover_color;
        text-decoration: underline;
      }
    }
    .submit-button {
      background-color: $primary;
      color: white;
      padding: 4px 25px;
      border: none;
      outline: none;
      font-size: 13px;
      border-radius: 5px;
      margin: 10px 0px;

      .submit-button-icon {
        font-size: 18px;
        margin-right: 10px;
      }
      &:hover {
        background-color: $primary_hover_color;
      }
    }
    .confome-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 13px;
      color: gray;
      padding-top: 10px;
      span {
        font-size: 13px;
        color: black;
      }
    }
    .resendotp {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 13px;
      color: gray;
      padding: 20px 0px;

      .resendotp-button {
        font-size: 12px;
        color: $primary;
        text-decoration: none;
        padding-left: 2px;
        font-weight: 600;
        &:hover {
          color: $primary_hover_color;
        }
      }
    }
    .otp-container {
      div {
        justify-content: center;
        span {
          display: none;
        }
        input {
          margin: 10px;
          border: none;
          border-bottom: 2px solid #d8d8d8;
          width: 25px !important;

          // &:focus {
          //   border-bottom: 2px solid $primary !important;
          //   border: none !important;
          // }
        }
      }
    }
  }

  @media (max-width: 525px) {
    .login-page-inner {
      justify-content: center !important;
    }
    .right {
      width: 100% !important;
    }
  }
}
.bg-image {
  background: url("../../Assets/Images/loginPage-banner.jpg");
  background-size: cover;
  background-position: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}