$primary: #0443c0;
$primary_hover_color: #0b5ed7;
$bg_body_color: #f0f1f8;
$gray: #f0f0f0;
$darkGray: #e9e9e9;

.catalogs-book {
  display: flex;
  align-items: center;
  .catalogs-image {
    margin: 0px 10px;
    font-size: 50px;
  }
}

.catalogs-content {
  font-size: 14px;
  font-weight: 500 !important;
}

.contact-information {
  .contact-title {
    border-bottom: 0.5px solid rgba(162, 162, 162, 0.693);
    padding-bottom: 10px;
    font-size: 20px;
  }
  .input-group-text {
    background-color: $primary !important;
    color: white;
    cursor: pointer;
    min-width: 170px;
    white-space: wrap !important;
  }
}

.catalogs-served-industry-inputs {
  .input-group-text {
    width: 50% !important;
    white-space: wrap !important;
  }
  @media (min-width: 992px) {
    .input-group {
      width: 80% !important;
    }
  }
}

@media (max-width: 768px) {
  .input-group {
    width: 7% !important;
  }
}



.submit-button {
  background-color: #f14b25;
  padding: 8px 30px;
  border-radius: 5px;
  margin: 10px 0px;
  color: white;
  border: none;
  &:hover {
    background-color: rgba(255, 43, 43, 0.733);
  }
}

@media (min-width: 768px) {
  .catalogs-inputs {
    width: 50%;
  }
}



// console.log("sdfsd")