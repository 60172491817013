// @import "../../variables/variables.scss";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,700;1,800&family=Teko:wght@300;400;500;600&display=swap");

// .product-container {
//   .navigation-title {
//     font-weight: 900 !important;
//     font-family: "Poppins", sans-serif;
//     font-family: "Teko", sans-serif;
//   }

//   .navigation {
//     background-color: #ececec;
//     padding: 10px 0px 0px 10px;

//     .navigation-user {
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       width: 100%;

//       .navigation-user-image {
//         border-radius: 50%;
//         width: 50px;
//         object-fit: cover !important;
//         margin-right: 1rem;
//       }
//     }

//     .navigation-list {
//       padding-left: 0;
//       margin-top: 1rem;

//       li {
//         list-style-type: none;
//         border-bottom: 1px solid white;
//         cursor: pointer !important;

//         a {
//           color: black;
//           text-decoration: none;
//           display: flex;
//           flex-wrap: wrap;
//           align-items: center;
//           padding: 10px;
//           transition: .1s;

//           .navigation-list-icon{
//             font-size: 20px;
//           }

//           &:focus{
//             background-color: $primary;
//             color: white;
//             transition: .1s;
//           }
//         }
//       }
//     }

//     @media (max-width:407px) {
//       .navigation-user-name{
//         h5{
//           margin: 20px 0px !important;
//           font-size: 18px;
//           display:inline !important;
//           text-align: center;
//           width: 100% !important;
//         }
//       }
//     }
//   }
// }



// .dash {
//   display: flex;
//   flex-direction: column;
// }

// .sidebar .list-group-item {
//   cursor: pointer;
// }

// .sidebar .list-group-item:hover,
// .sidebar .list-group-item.active {
//   background-color: #007bff;
//   color: white;
// }

// .logout-btn {
//   width: 100%;
//   margin-top: 20px;
//   background-color: #F04B25 !important;
//   color: whitesmoke !important;
// }

// .content {
//   padding: 20px;
//   height: 100% !important;
//   opacity: 1;
// }

// .content .filter-section {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .content .search-bar {
//   margin-bottom: 20px;
// }

// .content .menu-categories button {
//   margin-right: 10px;
// }

// .card img {
//   max-width: 100%;
//   height: auto;
// }

// @media (min-width: 992px) {
//   .accordion-button::after {
//     content: "";
//     display: inline-block;
//     width: 1.25rem;
//     height: 1.25rem;
//     margin-left: auto;
//     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' d='M1.5 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E");
//     transition: transform 0.2s ease-in-out;
//   }

//   .accordion-button:not(.collapsed)::after {
//     transform: rotate(90deg);
//   }
// }

// /* .accordion-button {
//   background-color: blueviolet ;
// } */

// .profile-dashboard > .accordion > .accordion-item {
//   margin: 2px 0px;
// }

// .profile-dashboard > .accordion > .accordion-item > .accordion-header button {
//   // background-color: blueviolet;
//   background-color: #0443C0;
//   padding: 20px 10px !important;
// }

// .profile-dashboard
//   > .accordion
//   > .accordion-item
//   > .accordion-header
//   [aria-expanded="true"] {
//   // background-color: aqua !important;
//   background-color: #F04B25 !important;
// }

// .profile-page-container {
//   /* background: linear-gradient(212deg, rgba(189,195,199,1) 0%, rgba(44,62,80,1) 100%); */
//   // background: linear-gradient(
//   //   212deg,
//   //   rgba(58, 123, 213, 1) 0%,
//   //   rgba(58, 96, 115, 1) 100%);
//   // background-color: #F04B25;
//   // color: aliceblue;
//   color: black;
//   padding: 10px;
//   -webkit-border-radius: 20px;
//   -moz-border-radius: 20px;
//   border-radius: 20px;
//   box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
//     0px 30px 60px -30px rgba(0, 0, 0, 0.3),
//     inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
// }

// .edit-profile-page-container{
//   box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
//   0px 30px 60px -30px rgba(0, 0, 0, 0.3),
//   inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
// padding: 40px !important;
// margin-top:20px ;
// border-radius: 10px;
// background-color: aliceblue;

// }

// .modal-box1{
//   display: flex;
//   align-items: center;
//   justify-content: center;
  
// }


// .edit-profile-page-btn-container{
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
// }

// .edit-profile-page-btn{
//   min-width: 100px !important ;
// }

// .edit-Password-container{
//   box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
//   0px 30px 60px -30px rgba(0, 0, 0, 0.3),
//   inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
// padding: 40px !important;
// margin-top:20px ;
// border-radius: 10px;
// background-color: aliceblue;
// /* width:90% !important; */
// }

// .MuiInputBase-root input{ 
//   width:100% !important;
// }

// .profile-dashboard>.accordion>.accordion-item>.accordion-header>.accordion-button{
//   font-size: 18px;
//   font-weight: 600;
//   /* letter-spacing: 0.5px; */
//   color: aliceblue;
// }



@import "../../variables/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,700;1,800&family=Teko:wght@300;400;500;600&display=swap");

.product-container {
  .navigation-title {
    font-weight: 900 !important;
    font-family: "Poppins", sans-serif;
    font-family: "Teko", sans-serif;
  }

  .navigation {
    background-color: #ececec;
    padding: 10px 0px 0px 10px;

    .navigation-user {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .navigation-user-image {
        border-radius: 50%;
        width: 50px;
        object-fit: cover !important;
        margin-right: 1rem;
      }
    }

    .navigation-list {
      padding-left: 0;
      margin-top: 1rem;

      li {
        list-style-type: none;
        border-bottom: 1px solid white;
        cursor: pointer !important;

        a {
          color: black;
          text-decoration: none;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px;
          transition: .1s;

          .navigation-list-icon{
            font-size: 20px;
          }

          &:focus{
            background-color: $primary;
            color: white;
            transition: .1s;
          }
        }
      }
    }

    @media (max-width:407px) {
      .navigation-user-name{
        h5{
          margin: 20px 0px !important;
          font-size: 18px;
          display:inline !important;
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }
}



.dash {
  display: flex;
  flex-direction: column;
}

.sidebar .list-group-item {
  cursor: pointer;
}

.sidebar .list-group-item:hover,
.sidebar .list-group-item.active {
  background-color: #007bff;
  color: white;
}

.logout-btn {
  width: 100%;
  margin-top: 20px;
  background-color: #F04B25 !important;
  color: whitesmoke !important;
}

.content {
  padding: 20px;
  height: 100% !important;
  opacity: 1;
}

.content .filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content .search-bar {
  margin-bottom: 20px;
}

.content .menu-categories button {
  margin-right: 10px;
}

.card img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .accordion-button::after {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' d='M1.5 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E");
    transition: transform 0.2s ease-in-out;
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg);
  }
}

/* .accordion-button {
  background-color: blueviolet ;
} */

.profile-dashboard > .accordion > .accordion-item {
  margin: 2px 0px;
}

.profile-dashboard > .accordion > .accordion-item > .accordion-header button {
  // background-color: blueviolet;
  background-color: #0443C0;
  padding: 20px 10px !important;
}

.profile-dashboard
  > .accordion
  > .accordion-item
  > .accordion-header
  [aria-expanded="true"] {
  // background-color: aqua !important;
  background-color: #F04B25 !important;
}

.profile-page-container {
  /* background: linear-gradient(212deg, rgba(189,195,199,1) 0%, rgba(44,62,80,1) 100%); */
  // background: linear-gradient(
  //   212deg,
  //   rgba(58, 123, 213, 1) 0%,
  //   rgba(58, 96, 115, 1) 100%);
  // background-color: #F04B25;
  // color: aliceblue;
  color: black;
  padding: 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
}

.edit-profile-page-container{
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
  0px 30px 60px -30px rgba(0, 0, 0, 0.3),
  inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
padding: 40px !important;
margin-top:20px ;
border-radius: 10px;
background-color: aliceblue;

}

.modal-box1{
  display: flex;
  align-items: center;
  justify-content: center;
  
}


.edit-profile-page-btn-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.edit-profile-page-btn{
  min-width: 100px !important ;
}

.edit-Password-container{
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
  0px 30px 60px -30px rgba(0, 0, 0, 0.3),
  inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
padding: 40px !important;
margin-top:20px ;
border-radius: 10px;
background-color: aliceblue;
/* width:90% !important; */
}

.MuiInputBase-root input{ 
  width:100% !important;
}

.profile-dashboard>.accordion>.accordion-item>.accordion-header>.accordion-button{
  font-size: 18px;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
  color: aliceblue;
}

.custom-tabs .nav-link {
  position: relative;
  overflow: hidden;
  transition: color 0.3s;
  z-index: 1;
}

.nav-link::before {
  content: '';
  position: absolute;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(8, 12, 223, 0.979), rgba(0, 0, 255, 0.568));
  transition: left 0.5s;
  z-index: 0;
  top:0;
}

.custom-tabs .nav-link:hover::before {
  left: 0;
}

.custom-tabs .nav-link:hover {
  color: white;
}

.custom-tabs .nav-link span {
  position: relative;
  z-index: 1;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color: (90deg, rgba(8, 12, 223, 0.979), rgba(0, 0, 255, 0.568))!important;
}

@media (max-width:420px) {
  .dsds h2{
    font-size: 12px;
  }
}
