
.cont-sty span{
  font-size: 20px;
  font-weight: 600;
}
.cont-sty svg{
  fill:rgb(244, 133, 126);
}
.con-lf{
  padding-left: 25px;
  font-size: 15px;
}






















* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contact-heading {
  text-align: center;
  font-family: sans-serif;
  font-weight: 600;
  color: #2f3645;
  // background: linear-gradient(
  //   210deg,
  //   rgba(206, 211, 212, 1) 0%,
  //   rgba(245, 246, 246, 1) 56%,
  //   rgba(168, 176, 178, 1) 100%
  // );
  padding: 20px;
  border-radius: 8px;
}

.about-contact-page {
  // background-color: #254336;
  height: 100vh;
  // color: white;
}

ul {
  list-style: none;
}

.cell {
  display: table-cell;
  // padding: 10px;
  vertical-align: middle; /* Ensures the content is vertically centered */
}

//   .cell:first-child {
//     text-align: right; /* Aligns images to the right in the first cell of each row */
//   }

.cell img {
  float: right; /* Ensures images are aligned to the right within the cell */
}

.contact-heading {
  text-align: center;
  font-family: sans-serif;
  font-weight: 600;
  // color: #0443c0;
  color: #2f3645;
  // background: linear-gradient(
  //   210deg,
  //   rgba(206, 211, 212, 1) 0%,
  //   rgba(245, 246, 246, 1) 56%,
  //   rgba(168, 176, 178, 1) 100%
  // );
  padding: 20px;
  border-radius: 8px;
}

.contact-web-links:link {
  // color: green !important;
  // color: #0443C0 !important;
  // color: #DFD0B8 !important;
  color: #0443C0 !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.contact-background-color{
  // background: linear-gradient(210deg, rgba(66,71,105,1) 0%, rgba(27,26,85,1) 48%, rgba(35,45,63,1) 100%);
  // background: linear-gradient(210deg, rgba(27,26,85,1) 48%, rgba(66,71,105,1) 48%, rgba(35,45,63,1) 100%);
  // background: #6B8A7A;

  // // background-image: linear-gradient(to right, #00b4db, #0083b0);
  // background: linear-gradient(45deg, rgba(235,244,246,1) 63%, rgba(55,183,195,1) 63%);
  // background: linear-gradient(45deg, rgba(235,244,246,1) 44%, rgba(55,183,195,1) 44%);
 border-radius: 15px;
 box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;}

.contact-info-container{
  // box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
}
