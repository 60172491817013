@import "../../variables/variables.scss";

.billing {
  margin-right: calc(-0 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0 * var(--bs-gutter-x)) !important;
  .billing-inner {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
    h4 {
      font-weight: 700;
    }
  }
  .diff-address {
    .diff-address-header {
      display: flex !important;
      align-items: center;
      justify-content: space-between !important;
      width: 100%;
      :nth-child(1) {
        font-size: 20px;
      }
    }
  }
  .checkout {
    display: flex;
    align-items: center;
    :nth-child(2) {
      font-size: 14px !important;
      font-weight: 600;
      padding-left: 10px;
    }
  }
  .order-form {
    padding-left: 20px;
  }

  .privacy-policy {
    font-size: 13px;
    cursor: pointer;
  }
  @media (max-width: 992px) {
    .order-form {
      padding: 0;
    }
  }
}

.input {
  width: 100%;
  border: 2px solid #ebebeb;
  outline: none;
  padding: 8px;
  border-radius: 3px;
  font-size: 14px;
}

.accordion-item {
  border: none !important;
}
.accordion-button {
  padding: 20px 0px !important;
}
.accordion-button::after {
  display: none !important;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  background-color: white !important;
}
.accordion-button:focus {
  border-color: white !important;
  box-shadow: none !important;
}
.accordion-body {
  padding: 10px 0px !important;
}

.choose-container {
  margin-right: calc(-0 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0 * var(--bs-gutter-x)) !important;

  .choose-button {
    width: 10%;
    height: 20px;
  }

  .choose-address {
    transition: 0.5s;

    .old-address {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;

      .add-icon {
        font-size: 30px !important;
        margin: 2px 0px;
      }
    }

    .sub-title {
      font-weight: 500 !important;
      font-size: 17px;
    }

    .old-user-name,
    .old-user-address {
      font-size: 14px !important;
      font-weight: 400;
      margin: 0;
    }

    &:hover {
      background-color: rgba(211, 211, 211, 0.596);
      transition: 0.5s;

      .add-icon {
        transition: 1s;
      }
    }
  }
}

.coupon-button {
  margin-top: 20px;
  border: none;
  color: $primary;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: $primary_hover_color;
  }
}