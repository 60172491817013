@import "../../../variables/variables.scss";

.form-select{
    border-radius: 0 !important;
}

.fastaners-table{
    border: .5px solid black;
    width: 100%;
    margin: 2rem 0rem;
    overflow-y: auto !important;

    th{
        padding: 10px;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background-color: $primary;        
    }
    tr{
        border: .5px solid #AFD7FF;
    }
    tbody{
        td{
            font-size: 13px;
            font-weight: bold;
            &:hover{
                background-color: #F5F5F5;
            }
        }
    }
}