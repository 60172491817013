.title {
  font-size: 15px;
  font-weight: 500;
}

.value {
  font-size: 12px;
  font-weight: 400;
}

.filter-container {
  display: flex;
  // flex-wrap: wrap;
  // box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
  //   rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

  .filter {
    // border-right: 1px solid gray;
    width: 30%;
    padding: 15px 0px !important;

    .filter-section {
      width: 90%;

      margin-bottom: 3px;

      .filter-title {
        font-size: 18px;
        text-transform: capitalize;
      }

      .filter-body {
        margin-left: 5px;
        height: 95px;
        overflow-y: auto !important;
        // background-color: red;

        input {
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }

        .filter-name {
          cursor: pointer;
        }
      }
    }
  }
.form-check:hover{
  background-color: blue;
}
  .table-container {
    overflow-x: auto !important;
    width: 100% !important;
    height: 80vh;
    padding: 15px 0px !important;

    .product-table {
      border: 1px solid black;
      .table-partNumber {
        margin: 20px !important;
        padding: 0 !important;
        --bs-table-bg-type: white !important;
      }

      td {
        padding: 20px 0px;
        font-size: 12px;
      }

      .title {
        font-size: 14px;
      }

      th,
      td {
        overflow: hidden !important;
        border-right: 1px solid black;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .filter {
      border: none;
      width: 100%;

      .filter-section {
        width: 100%;
      }
    }
  }
}
.filtercard-bg {
  background: #B6B4B4;
  transition: background-color 0.3s ease;

  &:hover {
    background: #0443C0;
    color: white;
  }
}
@media (max-width:768px) {
  .text-inputgroupsss{
    display: none ;
  }
  .fdfdfd{
    margin-left:auto ;
  }
}