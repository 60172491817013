.inquiry-section {
  background: linear-gradient(
    212deg,
    rgba(83, 120, 149, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding-top: 40px;
  padding-bottom: 40px;
}
.inquiry-page-heading {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  // color: #0443c0;
  color: #2f3645;
  background: linear-gradient(
    210deg,
    rgba(206, 211, 212, 1) 0%,
    rgba(245, 246, 246, 1) 56%,
    rgba(168, 176, 178, 1) 100%
  );
  padding: 20px;
  border-radius: 8px;
}

.inquiry-page-container {
  // background: linear-gradient(212deg, rgba(2,170,189,1) 0%, rgba(81,99,149,1) 100%);
  // color: white;
  // box-shadow: 0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11);
  //   box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45),
  //     25px 0 20px -20px rgba(0, 0, 0, 0.45),
  //     0px 25px 20px -20px rgba(0, 0, 0, 0.45),
  //     -25px 0 20px -20px rgba(0, 0, 0, 0.45);

  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px 0px rgba(10, 37, 64, 0.35);
  padding: 40px !important;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: aliceblue;
  margin-bottom: 0px;
}

// .inquiry-page-form {
//   input,
//   select,
//   textarea {
//     // box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1);
//     // box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.176);
//     // box-shadow: 0 1px 1px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15);
//     // background-color: #fff;
//   }
// }

.inquiry-page-form > .inquiry-form-submit-button {
  // width: 100%;
  margin-top: 1rem;
  margin-left: 46%;
  text-align: center;
}

// .inquiry-form-textareatextarea,
// .inquiry-form-select-lable + div {
//   // box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.176);
// //   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
// //     0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
// //   background-color: #fff;
// }


.row>.col-md-6>div>.form-label,.row>.col-md-12>div>.form-label{
    
    margin-top: 5px;
}

#select-subject>.notranslate::after{
content: "Select the Subject";

}