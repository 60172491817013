.list-style-none {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
  }
  
  .Empty-cart{
    width: 30vw;
  }

  
@media (max-width:768px) {
  
  .listProducts-banner{
    height: 100px !important;
  }
  .listProducts-banner>h2{
    font-size: 30px !important;
  }
}

@media (max-width:426px){



  .NoWishlistlists > .NoWishlistImage {
    width: 50vw !important;
    height: 50% !important;
  }
  .no-wishlist-content{
    font-size: medium;
  }
  .Empty-cart{
    width: 50vw;
  }
}